/*--------------------------------------------------------*\
	Content => Columns => Media Attributes
\*--------------------------------------------------------*/

#canvas--icons {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
}

.columns--backgroundOnly {
	height: 10rem;
}

.column {
    iframe {max-width: 100%;}
	&.media--left {
		display: flex;

		@include m(1024, 721) {
			display: block;
		}
		@include m(400) {
			display: block;
		}

		.icon__container {
			@include flex(0, 0, 6rem);

			@include m(560) {
				@include flex(0, 0, 5rem);
				flex-basis: 5rem;
			}
			@include m(440, 401) {
				@include flex(0, 0, 4rem);
				flex-basis: 4rem;
			}

			svg {
				width: 6rem;
				height: 6rem;

				@include m(560) {
					width: 5rem;
					height: 5rem;
				}
				@include m(440, 401) {
					width: 4rem;
					height: 4rem;
				}
			}
		}

		.content {
			flex-basis: auto;
			padding-left: 2rem;

			.browser--ie[class*="10"] &,
			.browser--ie[class*="11"] & {
				@include flex(0, 0, auto);

				@include m(560) {
					@include flex(0, 0, auto);
				}
				@include m(440, 401) {
					@include flex(0, 0, auto);
				}
			}

			@include m(1024, 721) {
				padding-left: 0;
			}

			@include m(720, 401) {
				padding-left: 1.5rem;
			}

			@include m(400) {
				padding-left: 0;
			}
		}
	}

	.icon__container {
		&--circle {
			// width: 6.5rem;
			// height: 8.5rem;
			// margin-bottom: 1.5rem;

			// @include m(1160) {
			// 	width: 9rem;
			// 	height: 9rem;
			// }

			// @include m(1060) {
			// 	width: 8rem;
			// 	height: 8rem;
			// }
		}

		&--center {
			margin: 0 auto;
			margin-bottom: 1rem;
		}
	}

	.media__container {
		width: 100%;
		margin-bottom: 2rem;
		@include m(768) {
			margin-bottom: 0;
		}

		&--center {
			img {
				display: block;
				margin: 0 auto;
			}
		}

		img {
			opacity: 0;
			transform: translate(0, 2.5rem);
			transition:
				opacity 0.5s ease-in,
				transform 0.5s ease-in;
        }
		&.image--reveal img {
				opacity: 1!important;
				transform: translate(0, 0)!important;
		}
	}
}

.media__container {
	@include m(9999, 768) {
		&.mediaAppendTo--right {
			width: auto;
			@include v-center();
			right: -5rem;
			max-width: calc(50% + 5rem);
		}

		&.mediaAppendTo--left {
			width: auto;
			@include v-center();
			left: -5rem;
			max-width: calc(50% + 5rem);
		}
	}

	img {
		opacity: 0;
		transform: translate(0, 2.5rem);
		transition:
			opacity 0.5s ease-in,
			transform 0.5s ease-in;
    }
    &.image--reveal img {
			opacity: 1!important;
			transform: translate(0, 0)!important;
	}
}

.wrapper--products {
	.columns--5 {
		.column--1 {
			&.column--media {
				@include m(1024, 761) {
					flex-basis: calc(100% / 3);
					max-width: calc(100% / 3);
				}
				@include m(760) {
					flex-basis: 50%;
					max-width: 50%;

					.media__container {
						margin-bottom: 0.75rem;
					}
				}
				@include m(480) {
					flex-basis: 100%;
					max-width: 100%;
				}
			}

			.media__container {
				max-width: 10rem;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

