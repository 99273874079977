/*--------------------------------------------------------*\
	Mixins
\*--------------------------------------------------------*/

@import "../config/moduleIncludes";

/*--------------------------------------------------------*\
	Content
\*--------------------------------------------------------*/

@import "content/columns/index";
@import "content/columns/alignment";
@import "content/columns/wrap";
@import "content/columns/media";
@import "content/columns/quote";
@import "content/searchFunds";
@import "../components/carousel/slider";
@import "../components/landing/index";



.wrapper--how-we-help #block--content__6 {
    padding-bottom: 12rem!important;
    @include m(768) {
        padding-bottom: 6rem;
    }
}
.playButton__container {
    @include center();

    .columns--2 .column & {
        top: 40%;
        @include m(1100) {
            top: 35%;
        }
        @include m(935) {
            top: 32%;
        }
        @include m(885) {
            top: 30%;
        }
        @include m(800) {
            top: 27%;
        }
        @include m(768) {
            top: 50%;
        }
    }

    .columns--2 .column--quotes:first-child  & {
        @include m(991) {
            left: 45%;
        }
        @include m(768) {
            left: 50%;
        }
    }
    .columns--2 .column--quotes:last-child  & {
        @media (min-width: 1191px) {
            right: 30%;
        }
        @include m(1190) {
            right: 32%;
        }
        @include m(840) {
            right: 30%;
        }
        // @include m(991) {
        //     left: 45%;
        // }
        // @include m(768) {
        //     left: 50%;
        // }
    }

    svg {
        width: 7.5rem;
        height: 7.5rem;

        .column--quotes & {
            width: 5.5rem;
            height: 5.5rem;
        }

        path {@include transition(fill);}
        circle {@include transition(all);}

        @include m(540) {
            width: 4rem;
            height: 4rem;
        }
    }
}
.modal__trigger:hover svg {
    path {fill: $blue--light;}
    circle {stroke: $blue--light;}
}

.wrapper--companies #block--content__1 .column {
    @media (min-width: 1450px) {
        padding-left: 0;
        padding-right: 0;
    }
}
.wrapper--connect .content {

    p:first-child {
        margin-bottom: 2rem;
    }
    a:not(.button) {
        font-weight: 300!important;
        color: $blue--light!important;
        &:hover {
            color: $blue--regular!important;
        }
    }
}
