/*--------------------------------------------------------*\
    Home Page Landing
\*--------------------------------------------------------*/

.block--landing {
    position: relative;
    padding: 0;
    img.container--bgImage {
        z-index: 0!important;
    }
    .video__container {
        @include cover();
        // position:relative;
        z-index: 1;
        overflow: hidden;
    }
    video.fillWidth {
        min-height: 100%;
        min-width: 100%;
        height: 100%;
        width: 100%;

        object-fit: cover;
    }
    .block__container {
        // @media (min-width: 1451px) {
        //     transform: translateY(-100%);
        // }
        // @media (max-width: 1450px) {
        //     @include cover();

        //     .container {
        //         width: 100%;
        //         margin: 0;
        //     }
        // }
    }
    .overlay {
        @include cover();
        background-color: rgba(#000, 0.15);
        z-index: 2;
    }
    .column--end {
        padding: 0;
        margin: 2.5rem 0!important;
    }
}