/*--------------------------------------------------------*\
	Content => Columns => Wrap
\*--------------------------------------------------------*/

.columns {

	&--wrap {
		flex-wrap: wrap;

		.column {
			margin-bottom: 5rem;

			@include m(1024) {
				margin-bottom: 3.5rem;
			}
			@include m(768) {
				margin-bottom: 2.5rem;
			}
		}
	}

	&--wrap:not(.columns--endRow) {
		.column--end {
			margin-bottom: 0;

			@include m(1024) {
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
			@include m(768) {
				margin-bottom: 2.5rem;
			}
		}
	}

	&--wrap.columns--endRow {
		.column.column--row:last-child {
			margin-bottom: 0;
		}

		.column.column--row:not(.column--box):first-child {
			margin-bottom: 3.5rem;

			&.column--end {
				margin-bottom: 0;
			}
		}
	}

	&--nowrap {
		flex-wrap: nowrap;

		@include m(768) {
			flex-wrap: wrap;
		}
	}
}