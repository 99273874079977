/*--------------------------------------------------------*\
	Layout Mixins => Utility Functions
\*--------------------------------------------------------*/

@mixin auto() {
	margin: {
		left: auto;
		right: auto;
	}
}

/*--------------------------------------------------------*\
	Layout Padding/Margin

    TODO: Cancel padding when it's manually defined.
\*--------------------------------------------------------*/

@mixin containerSpacing($direction: 'both', $style: 'padding') {
	// 'x': (3.5rem, 3rem, 2.75rem, 2rem, 1.5rem),
    $spacing: (
        'x': (2rem, 2.5rem,2rem,2rem,1.5rem),
		'y': (4rem, 3.5rem, 2rem),
	);

	@if ($direction == 'both') {
		#{$style}: {
			top: nth(map-get($spacing, 'y'), 1);
			right: nth(map-get($spacing, 'x'), 1);
			bottom: nth(map-get($spacing, 'y'), 1);
			left: nth(map-get($spacing, 'x'), 1);
		}
        @include m(1280) {
            #{$style}: nth(map-get($spacing, 'y'), 1) nth(map-get($spacing, 'x'), 2);
        }

		@include m(1024) {
			#{$style}: nth(map-get($spacing, 'y'), 1) nth(map-get($spacing, 'x'), 3);
		}

		@include m(768) {
			#{$style}: nth(map-get($spacing, 'y'), 2) nth(map-get($spacing, 'x'), 3);
		}

		@include m(640) {
			#{$style}: nth(map-get($spacing, 'y'), 2) nth(map-get($spacing, 'x'), 4);
		}

		@include m(480) {
			#{$style}: nth(map-get($spacing, 'y'), 3) nth(map-get($spacing, 'x'), 5);
		}
	}

	@elseif ($direction == 'x') {
		#{$style}: {
			right: nth(map-get($spacing, $direction), 1);
			left: nth(map-get($spacing, $direction), 1);
		}
        @include m(1280) {
            #{$style}: {
                right: nth(map-get($spacing, $direction), 2);
                left: nth(map-get($spacing, $direction), 2);
            }
        }

		@include m(1024) {
			#{$style}: {
				right: nth(map-get($spacing, $direction), 3);
				left: nth(map-get($spacing, $direction), 3);
			}
		}
		@include m(768) {
			#{$style}: {
				right: nth(map-get($spacing, $direction), 3);
				left: nth(map-get($spacing, $direction), 3);
			}
		}
		@include m(640) {
			#{$style}: {
				right: nth(map-get($spacing, $direction), 4);
				left: nth(map-get($spacing, $direction), 4);
			}
		}
		@include m(480) {
			#{$style}: {
				right: nth(map-get($spacing, $direction), 5);
				left: nth(map-get($spacing, $direction), 5);
			}
		}
	}

	@elseif ($direction == 'y') {
		#{$style}: {
			top: nth(map-get($spacing, $direction), 1);
			bottom: nth(map-get($spacing, $direction), 1);
		}

		@include m(768) {
			#{$style}: {
				top: nth(map-get($spacing, $direction), 2);
				bottom: nth(map-get($spacing, $direction), 2);
			}
		}
		@include m(480) {
			#{$style}: {
				top: nth(map-get($spacing, $direction), 3);
				bottom: nth(map-get($spacing, $direction), 3);
			}
		}
	}
}

/*--------------------------------------------------------*\
	Layout Padding/Margin => Deprecated
\*--------------------------------------------------------*/

@mixin containerMargin($style, $add: 0) {
	@if ($add != 0) {
		#{$style}: calc(3.5rem + #{$add});

		@include m(1024) {
			#{$style}: calc(3rem + #{$add});
		}
		@include m(768) {
			#{$style}: calc(2.75rem + #{$add});
		}
		@include m(640) {
			#{$style}: calc(2rem + #{$add});
		}
		@include m(480) {
			#{$style}: calc(1.5rem + #{$add});
		}
	}

	@else {
		#{$style}: 3.5rem;

		@include m(1024) {
			#{$style}: 3rem;
		}
		@include m(768) {
			#{$style}: 2.75rem;
		}
		@include m(640) {
			#{$style}: 2rem;
		}
		@include m(480) {
			#{$style}: 1.5rem;
		}
	}
}

@mixin blockPadding() {
	padding: 5rem 0;
	@include m(768) {
		padding: 4rem 0;
	}
	@include m(480) {
		padding: 3.5rem 0;
	}
}

// make style an array to apply across padding/margin, etc.
@mixin blockSpacing($style, $mutate: 0rem) {
	@if ($mutate != 0) {
		#{$style}: #{5rem + $mutate};
		@include m(768) {
			#{$style}: #{4rem + $mutate};
		}
		@include m(480) {
			#{$style}: #{3.5rem + $mutate};
		}
	}

	@else {
		#{$style}: 5rem;
		@include m(768) {
			#{$style}: 4rem;
		}
		@include m(480) {
			#{$style}: 3.5rem;
		}
	}
}

/*--------------------------------------------------------*\
	Flex Layouts
\*--------------------------------------------------------*/

@mixin flex($shrink, $grow, $basis) {
	flex-shrink: $shrink;
	flex-grow: $grow;
	flex-basis: $basis;
}

/*--------------------------------------------------------*\
	Layout Padding/Margin
\*--------------------------------------------------------*/

@mixin getHeaderHeight($style: 'padding-top') {
	#{$style}: 4rem;

	@include m(840) {
		#{$style}: 3.6rem;
	}
}

.reverse-order {
    .column--1:first-child {
        order: 2;
    }
    .column--1:last-child {
        order: 1;
    }
}
