/*--------------------------------------------------------*\
	Content => Columns
\*--------------------------------------------------------*/

.container--columns,
.container--icons {
	width: auto;
	flex: 0 1 auto;
	justify-content: space-between;
	align-items: flex-start;
    .columns--wrap & {
        flex-wrap: wrap;
    }
	&:not(.columns--border) {
		// margin: 0 -2rem;

		&.container--icons {
			margin: 0;
		}

		@include m(848) {
			margin: 0 -2rem;
		}
	}
    &.columns--border {
        .column {
            padding: 0 2rem;
        }
        @include m(768) {
            margin: 0 -2rem;
        }
    }
    .alignImg--bottom & .column--media.column--end {
        padding-left: 4rem;
    }
}

.container--icons {
	width: 100%;
}

.columns {
	@for $i from 1 through 6 {
		&--#{$i} {
			@for $j from 1 through 6 {

				.column--#{$j} {
					flex-basis: #{($j / $i * 100%)};
					max-width: #{($j / $i * 100%)};

					&.column--box {
						// Was (5rem + 1rem * #{$i - 1}) ==> Not sure why?
						@include flex(0, 0, calc(#{$j} / #{$i} * 100% - ((1rem * #{$i - 1}) / #{$i})));

						@include m(1024, 601) {
							@include flex(0, 0, calc(50% - 0.5rem));
							max-width: 50%;
							margin-bottom: 5rem;

							&:nth-last-child(2):not(nth-child(2)),
							&:last-child {
								margin-bottom: 0;
							}
						}

						@include m(600) {
							@include flex(0, 0, calc(100% - 3rem));
							margin: 0 0 4rem;
						}
					}

					@include m(768) {
						flex-basis: 100%;
						max-width: 100%;
						margin-bottom: 2rem;

						&:last-child {
							margin-bottom: 0;
						}
					}

					// Temporary Fix
					@if ($i == 5) {
						@include m(9999, 1025) {
							&.column--box {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			@if ($i > 2) {
				@include m(1024, 769) {
					.column:not(.column--row):not(.column--box) {
						flex-basis: 50%;
						max-width: 50%;
						margin-bottom: 3.5rem;

						&:nth-last-child(2),
						&:last-child {
							margin-bottom: 0;
						}

						&.column--icon {
							flex-basis: calc(100% / 3);
							max-width: calc(100% / 3);
							margin-bottom: 5rem;

							&:nth-child(4),
							&:nth-child(5),
							&:nth-child(6) {
								margin-bottom: 3.5rem;
							}
						}
					}
				}

				@include m(720) {
					.column.column--icon {
						flex-basis: 50%;
						max-width: 50%;
						margin-bottom: 3rem;
					}
				}

				@include m(440) {
					.column.column--icon {
						flex-basis: 100%;
						max-width: 100%;
						margin-bottom: 3rem;
					}
				}

				@include m(1024) {
					&.columns--nowrap {
						flex-wrap: wrap;
					}
				}
			}
		}
	}

	&--2 {
		@include m(768) {
			.column {
				order: 1;
				margin-bottom: 2rem;

				&:not(.column--media):last-child {
					margin-bottom: 2rem;
				}

				&--media {
					order: 2;
					margin-bottom: 0;
				}
			}
		}
	}

	&--4, &--5, &--6 {
		@include m(9999, 1025) {
			&:not(.columns--border) {
				margin: 0 -1.5rem;

				&.container--icons {
					margin: 0;
				}
			}

			.column {
				padding: 0 1.5rem;
			}
		}
	}

	&--6 {
		.column {
			padding: 0 1rem;
		}
	}

	@include m(1120) {
		&--3:not(.columns--border) {
			margin: 0 -1.5rem;

			.column {
				padding: 0 1.5rem;
			}
		}
	}

    // Might need to switch this up for different background colors?
    // For borders use stretch + margin: auto;
    &--border .column:not(:last-child){
        border-right: 1px solid $grey--light;
        @include m(768) {
            border: 0;
        }
    }
}

.column {
	@include flex(0, 1, auto);
    // @media (min-width: )
    @media (min-width: 1450px) {
        padding: 0 2.5rem;
    }
    @media (max-width: 1449px) {
        .columns--2 & {
            &:first-child {
                padding-right: 2rem;
            }
            &:nth-child(2) {
                padding-left: 2rem;
            }
        }
        .columns--3 &--2 {

              padding-right: 2rem;
             + .column--1 {
                padding-left: 4rem;
            }
        }
    }
    // @media (min-width: 1281px) and (max-width: 1449px) {
    //     padding: 0 2rem;
    // }
    // padding: 0 2rem;

	@include m(848) {
		padding: 0 2rem;
        .columns--3 &--2 {

              padding: 0 1.5rem;
             + .column--1 {
                padding: 0 1.5rem;
            }
        }
	}

	&:last-child:not(.column--box) {
		border: 0;
	}

	// Column => Full row
	&--row {
		flex-basis: 100%;
		max-width: 100%;
	}



	+ .button__container {
		padding: 0 2.5rem;
		margin-top: 0;
	}

	&--offset {
		position: relative;
		.content {
			width: 100%;
			position: absolute;
			top: -22.5rem;
			left: 2.5rem;
		}
	}

	.content {
		img {
			margin: 0;

			&:only-child {
				@include m(720) {
					margin: 0 auto;
				}
			}
		}
	}
}

#icon__columns {
    padding-top: 9rem;
    @include m(769) {
        padding-top: 3.5rem;
    }
    .column--media,
    .column--icon {
        margin-top: 12px;
        padding: 3% 2.8rem;
        svg {
            display: block;
            margin: 0 auto;
            width: 6.5rem;
            height: 8.5rem;


            @include m(973) {
                width: 4.5rem;
                height: 6.5rem;
            }
        }
        &:last-child svg {
            width: 5.5rem;
            height: 8.5rem;

             @include m(973) {
                width: 3.5rem;
                height: 6.5rem;
            }

        }
        h3 {
            font-family: "Open Sans",Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.375;
            text-transform: uppercase;
            margin-bottom: 0.25rem;
        }

        h4 {
            font-family: "Open Sans",Helvetica, Arial, sans-serif;
            font-size: 3rem;
            font-weight: 700;
            line-height: 1;
            display: block;
            margin-bottom: 1.6rem;
        }
    }
}

.wrapper--how-we-help {
    .block#block--content__1 {
        h2 {font-weight: 400; line-height: 1.75;}

    }
    .block#block--content__2 {
        padding-top: 1.5rem;
    }
    .block:not(#block--content__1) {
        padding-bottom: 0!important;
        margin-bottom: 6rem;
    }
    .block#block--content__6 {
        padding-bottom: 4rem!important;
    }
}

