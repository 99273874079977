/*--------------------------------------------------------*\
	Fonts
\*--------------------------------------------------------*/

@mixin font($size, $lineHeight: 1.375, $style: normal) {
	font-size: $size;
	line-height: $lineHeight;
	font-style: $style;

	@if ($style == 'bold' or $style == bold or $style == 600) {
		font-weight: 600;
	}

	@if ($style == 'italic' or $style == italic) {
		font-weight: italic;
	}
}

body {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.5;
	font-style: normal;
	color: $body;

	h1, h2, h3, h4, h5, h6 {
		font-family: 'brandon-grotesque', Helvetica, Arial, sans-serif;
		line-height: 1.125;
        text-transform: uppercase;
	}

    h2, h3, h4 {
        color: $blue;
    }

    button {
        outline: none;
    }

    .button {
        font-family: 'brandon-grotesque', Helvetica, Arial, sans-serif;
        line-height: 1;
        // font-weight: 600;
        color: $body;
    }

	strong, b {
		font-weight: 600;
	}

	em, i {
		font-style: italic;
	}

    blockquote {
        p:last-child {
            font-family: 'brandon-grotesque', Helvetica, Arial, sans-serif;
            font-size: 0.875rem;
            font-weight: 600;
        }
    }
}