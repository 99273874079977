.block--stats {

    &.bg--color {
        .column--row {
            padding: 0 1rem;
            text-align: center;
            h2 {
                font-size: 2.5rem;
                @include m(420) {
                    font-size: 1.25rem;
                }
            }
        }
        .container {
            padding: 0 2rem;
        }
        .column--1 {
            padding: 0 1rem;
            margin-bottom: 0;
            @include m(1024, 769) {
                margin-bottom: 2rem!important;
            }
            @include m(768) {
                margin-bottom: 2rem!important;
                &:last-child {
                    margin-bottom: 0!important;
                }
            }
            .content {

                h3, h4 {
                    color: #cc3333;
                }

                h3 {
                    font-size: 4rem;
                    font-weight: 700;
                }
                h4 {
                    font-size: 1.75rem;
                    font-weight: 400;
                }
                .small {
                    font-size: 0.75rem;
                }
                padding: 2rem;
                background-color: white;
                color: $body;
                text-align: center;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }
}