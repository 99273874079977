/*--------------------------------------------------------*\
	Positioning
\*--------------------------------------------------------*/

@mixin v-center($top: 50%) {
	position: absolute;
	top: $top;
	transform: translateY(-50%);
}

@mixin h-center() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin un-center() {
	position: relative;
	top: 0;
	left: 0;
	transform: translate(0,0);
}

@mixin bg-center() {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

@mixin cover($hidden: false) {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;

	@if ($hidden == true) {
		opacity: 0;
		// visibility: hidden;
		cursor: pointer;
	}
}

.cover {
	@include cover();
}

.cover--hidden {
	@include cover($hidden: true);
}

@mixin clearfix {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

@mixin flex($shrink, $grow, $basis) {
	flex-shrink: $shrink;
	flex-grow: $grow;
	flex-basis: $basis;
}