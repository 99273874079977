/*--------------------------------------------------------*\
	Content => Columns => Alignment
\*--------------------------------------------------------*/

.columns {

	// Vertical Alignment
	&--alignTop {
		&, .container--icons {
			align-items: flex-start;
		}
	}
	&--alignMiddle {
		&, .container--icons {
			align-items: center;
		}
	}
	&--alignBottom {
		&, .container--icons {
			align-items: flex-end;
		}
	}
	&--alignStretch {
		&, .container--icons {
			align-items: stretch;
		}
	}

	// Horizontal Alignment
	&--alignLeft {
		&, .container--icons {
			justify-content: flex-start;
		}
	}
	&--alignCenter {
		&, .container--icons {
			justify-content: center;
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}
	&--alignRight {
		&, .container--icons {
			justify-content: flex-end;
		}
	}
	&--alignSpread {
		&, .container--icons {
			justify-content: space-between;
		}
	}
	&--alignAround {
		&, .container--icons {
			justify-content: space-around;
		}
	}
}
