/*--------------------------------------------------------*\
	Colors
\*--------------------------------------------------------*/
$white: #ffffff;
$body: #333333;
$grey--light: #b7b3c5;
$grey--background: #f2f4f2;
$grey--dark: #333333;
$blue--light: #42c8f4;
$blue--med--light: #00aeef;
$blue--pale: #7ca4dd;
$blue--regular: #15699d;
$blue: $blue--regular;
$blue--dark: #0f2434;
$red: #cb2c3e;
$sand: #cec3b5;

$colors:
         'white' transparent,
         'grey--light' $grey--light,
         'grey--background' $grey--background,
         'grey--dark' $grey--dark,
         'blue' $blue,
         'blue--regular' $blue--regular,
         'blue--light' $blue--light,
         'blue--dark' $blue--dark,
         'red' $red,
         'sand' $sand;

@each $color in $colors {
    $color__name: nth($color, 1);
    $color__var: nth($color, 2);

    .bg--#{$color__name} {
        background-color: $color__var;

        @if (
            $color__name != 'white' and
            $color__name != 'grey--light' and
            $color__name != 'none' and
            $color__name != 'grey--background'
        ) {

            &, .content {
                color: $white;
            }

            .content {
                h1, h2, h3, h4 {
                    color: $white;
                }
            }
        }
    }
}
// @function map-get-deep($map, $keys...) {
// 	@each $key in $keys {
// 		$map: map-get($map, $key);
// 	}

// 	@return $map;
// }

// $colors: (
// 	'white': #ffffff,
// 	'body': #333333,
// 	// 'grey': (
// 	// 	'light': #b7bec5,
// 	// 	'background': #f2f4f2,
// 	// 	'dark': #333333,
// 	// ),
// 	'blue': (
// 		'light': #42c8f4,
// 		'regular': #15699d,
// 		'dark': #0f2434,
// 	),
// 	'red': #cb2c3e,
// 	'sand': #cec3b5,
// );

// $blue: map-get-deep($colors, 'blue', 'regular');
// $blue--light: map-get-deep($colors, 'blue', 'light');
// $blue--dark: map-get-deep($colors, 'blue', 'dark');
// $red: map-get($colors, 'red');
// $sand: map-get($colors, 'sand');
// $white: map-get($colors, 'white');
// $grey--light: map-get-deep($colors, 'grey', 'light');
// $grey--bg: map-get-deep($colors, 'grey', 'background');
// $grey--dark: map-get-deep($colors, 'grey', 'dark');
// $body: $grey--dark;

/*--------------------------------------------------------*\
	Backgrounds
\*--------------------------------------------------------*/

// @each $color-name, $color-code in $colors {
// 	.bg--#{$color-name} {
// 		background-color: $color-code;
// 	}
// }


.bg--grey--background {
    background-color: #f2f4f2;
}