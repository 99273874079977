/*--------------------------------------------------------*\
	Flickity => Slider Config
\*--------------------------------------------------------*/

.flickity-viewport,
.flickity-slider,
.is-draggable,
.flickity-enabled {
	outline: none;
}

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	// overflow-y: hidden;
 //    overflow-x: visible;
	position: relative;
}

.flickity-enabled.is-draggable {
	tap-highlight-color: transparent;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: grabbing;
}

/*--------------------------------------------------------*\
	Flickity => Slides
\*--------------------------------------------------------*/

.slider {
	&, .flickity-viewport,
	.flickity-slider,
	.slider__item {
		width: 100%;
	}

	@for $i from 1 through 4 {
		&--#{$i} {
			.slider__item {
				width: calc((100% / #{$i}) - (1rem * #{$i - 1} / #{$i}));

				@if ($i != 1) {
					margin: 0 0.5rem;
				}
			}
		}
	}

	&__item {
		// overflow: hidden;
		img {
			margin: 0 auto;
			max-width: 100%;
		}

		&.is-selected {
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&[data-navigation="dots"] {
		width: 100%;
		max-width: 55rem;
		margin: 0 auto;

		@include m(640) {
			padding: 0;
		}
	}
}

/*--------------------------------------------------------*\
	Flickity => Image Slide Pairing
\*--------------------------------------------------------*/

.column--slider__images {
	display: block;
	width: 100%;
	position: relative;
	max-width: 73rem;
	margin: 0 auto;
}

.slider__image {
	&--container {
		display: block;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(opacity);
	}

	&--active {
		opacity: 1;
		visibility: visible;
	}
}

/*--------------------------------------------------------*\
	Flickity => Navigation Dots
\*--------------------------------------------------------*/

.nav--slider {
	.slider__dots {
		display: block;
		margin-top: 2rem;
		padding: 0;
		list-style: none;
		line-height: 1;

		@include m(640) {
			margin: 1.5rem auto;
		}
	}

	// .dot__item {
	// 	display: inline-flex;
	// 	align-items: center;
	// 	justify-content: flex-start;
	// 	width: 0.75rem;
	// 	height: 0.75rem;
	// 	margin: 0 0.25rem;
	// 	border-radius: 50%;
	// 	cursor: pointer;
	// 	color: $white;
	// 	background-color: $grey;
	// 	@include transition(background-color);

	// 	&:hover,
	// 	&--active {
	// 		background: $grey--medium;
	// 	}

	// 	span {
	// 		// @include md(1.5rem, 1);
	// 		margin: 0;
	// 	}
	// }
}

/*--------------------------------------------------------*\
	Flickity => Navigation Arrows
\*--------------------------------------------------------*/

.slider {
	&[data-navigation="arrows"] {
		padding: 0 1rem;
		// @include m(640) {
		// 	display: none;
		// }
	}
	.flickity-prev-next-button {
		@include v-center;
		width: 1.75rem;
		height: 1.75rem;
		border: none;
		cursor: pointer;

		path {
			// fill: $blue--light;
			@include transition(fill);
		}

		&:hover {
			path {
				// fill: $orange;
			}
		}

		&.next {
			right: -0.5rem;
		}

		&.previous {
			left: -0.5rem;
		}

	}
}
.slider--quotes button.flickity-prev-next-button {
    width: 1.65!important;
    height: 1.65!important;
    position: absolute;
    top: 50%;
    svg {
        width: 1.65!important;
        height: 1.65!important;
        path {
            fill: $blue--regular;
        }
    }
}


/*--------------------------------------------------------*\
	Flickity => Navigation Bar
\*--------------------------------------------------------*/

.nav--slider {
	display: block;
	width: 100%;
}

.slider__bar {
	width: 100%;
	display: flex;
	background-color: #fff;
}

.bar__item {
	flex: 1;
	height: 0.25rem;
	background-color: $white;
	cursor: pointer;
	transition: transform 0.5s ease-in-out;

	// &--out {
	// 	transform-origin: right center;
	// 	transform: scaleX(0);
	// }

	// &--in {
	// 	transform-origin: left center;
	// 	animation: scaleIn 0.5s;
	// }

	// &--outForward {
	// 	transform-origin: right center;
	// 	transform: scaleX(0);
	// }

	// &--inFoward {
	// 	transform-origin: left center;
	// 	animation-name: scaleIn;
	// 	animation-duration: 0.5s;
	// }

	// &--inReverse {
	// 	transform-origin: right center;
	// 	transform: scaleX(1);
	// }

	// &--outReverse {
	// 	transform-origin: left center;
	// 	animation-duration: scaleOut;
	// 	animation-duration: 0.5s;
	// }

	&:hover {

	}

	&--active {
		// background-color: $orange;
	}
}

