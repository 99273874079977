/*--------------------------------------------------------*\
	Transitions
\*--------------------------------------------------------*/

$timing: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$transition__timing: 0.25s;
$transition__fx: ease-in;
$transition: (
	'timing': 0.25s,
	'timingFunction': ease-in
);

@mixin transition($prop...) {
	@if ($prop) {
		transition-property: $prop;
	}

	@else {
		transition-property: color;
	}

	transition-duration: map-get($transition, 'timing');
	transition-timing-function: map-get($transition, 'timingFunction');
}

/*--------------------------------------------------------*\
	Links
\*--------------------------------------------------------*/

@mixin links($prop: all, $color: $blue--regular, $hover: $blue--light) {
	@include transition(#{$prop});
	color: #{$color};

	&:not(.button) {
		&:hover,
		&:focus,
		&:active {
			color: #{$hover};
		}
	}
}

/*--------------------------------------------------------*\
    Button
\*--------------------------------------------------------*/

@mixin button($color: $blue) {
    font-size: 0.875rem;
    color: $color;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    // padding: 0.375rem 1.5rem;

    border: 3px solid $color;
    border-radius: 0;
    text-transform: uppercase;
    @include transition(color, background-color);

    &:hover {
        background-color: $color;

        @if ($color == $white) {
            color: $blue;
        }

        @else {
            color: $white;
        }
    }

    .bg--color.bg--blue--regular & {
        color: $white;
        border-color: $white;
        &:hover {
            background-color: $white;
            color: $blue;
        }
    }
}

/*--------------------------------------------------------*\
	Display
\*--------------------------------------------------------*/

.hide {
	display: none;
}

.show {
	display: block;
}

/*--------------------------------------------------------*\
	Borders
\*--------------------------------------------------------*/

@mixin separator($borderWidth: 0.0625rem, $padding: 0.75rem, $color: $body) {
	border-right: #{$borderWidth} solid $color;
	padding: 0 #{$padding};

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
		border-right: 0;
	}
}
