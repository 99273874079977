/*--------------------------------------------------------*\
    Content => Columns => Quotes (Slider)
\*--------------------------------------------------------*/

.container.container--columns {
    align-items: stretch;
}

.container--quotes .column:not(.column--quotes) {

    @media (min-width: 769) and (max-width: 1440px) {

        padding: 0 2rem 0 4rem;
    }
    @include m(768) {
        order: 1;
      // padding: 0 2rem;
    }
    @include m(420) {
        .content h3 a {
            display: block!important;
            margin-top: 1rem;
        }
    }

    .wrapper--how-we-help & {
        h3 {
            font-weight: 400;
            font-size: 1.75rem;
        }
        p {
            font-size: 1rem;
        }
        &:first-child {
            padding: 0 4rem 0 0;
            @include m(800) {
                padding: 0 2rem;
            }
        }
        &:last-child {
            padding: 0 0 0 4rem;
            @include m(768) {
                padding: 0 2rem;
            }
        }
    }
}
.column--quotes {

    width: 100%;
    height: 100%;
    position: relative;

    @include m(1280) {
        padding: 0 1rem 0 0;
    }
    @include m(768) {
        order: 2!important;
        margin-bottom: 0;
        padding: 0 2rem;
    }
    @include m(480) {
        padding: 0;
    }
    // }

    .slider__item {
        width: 100%;
        height: 100%;
        height: 450px;
        position: relative;
        @include bg-center();
        background-size: contain;
        background-position: 50% 0;

        @include m(1200) {
            height: 400px;
        }
        @include m(841) {
            height: 350px;
        }
        @include m(768) {
            margin-bottom: 1rem;
            background-size: cover;

        }
        @include m(420) {
            margin-top: 0;
        }
        @include m(380) {
            min-height: 180px;
            background-size: cover;
        }
    }
    .flickity-enabled .slider__item {
        opacity: 0;
        &.is-selected {
            opacity: 1;
        }
    }

    .quote__container {
        position: absolute;
        top: 100%;

        transform: translateY(-150px);

        .wrapper--home & {
            transform: translateY(-75px);
        }

        background-color: $blue--dark;
        color: $white;
        font-size: 1.1rem;
        padding: 4%;

        @include m(945) {
            transform: translateY(-200px);
        }

    }

    blockquote p {
        line-height: 1.675;
        &:last-child {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: 0.9rem;
            font-weight: 300;
        }
        @include m(1024) {
            font-size: 1rem;
        }
        @include m(480) {
            font-size: 0.9rem;
        }

    }

    &:first-child {
        @include m(1280) {
            padding: 0 2rem 0 0;
        }
        @include m(768) {
            width: calc(100% - 4rem);
            left:0;
            padding: 0 2rem;
        }

        .quote__container {
            left: 20%;
            width: 100%;
            @include m(1085) {
                left: 5%;
            }
            @include m(826) {
                left: 1.5rem;
            }
            @include m(800) {
                left: 2rem;
            }
            @include m(768) {
                top: auto;
                bottom: -4rem;
                transform: unset;
                width: 100%;
                left:0;
                // margin: 0 2rem;
            }
        }
            // @include m(730) {
            //     bottom: -4rem;
            //     // position: static;
            //     // transform: translateY();
            //     width: calc(100% - 4rem);
            //     margin: 0 auto;
            // }
        @include m(768) {
            width: calc(100% - 4rem);
            left:0;
            // margin: 0 1rem;
        }

    }
    &:last-child {
        @include m(1280) {
            padding: 0 0 0 2rem;
        }
        @include m(800) {

        }
        @include m(768) {
            width: calc(100% - 4rem);
            left: 0;
            // margin: 0 1rem;
            padding: 0 2rem;
        }
        .quote__container {
            left: -20%;
            width: 100%;
            background-color: $blue--regular;
             @include m(1085) {
                left: -5%;
            }
            @include m(826) {
                left: -5rem;
            }
            @include m(800){
                left: -2rem;
            }
            @include m(768) {
                top: auto;
                bottom: -4rem;
                transform: unset;
                width: 100%;
                left:0;
                // margin: 0 2rem;
            }
            @include m(480) {
                bottom: -3rem;
            }
        }
    }

    .reverse-order & {
        &:last-child {

            .quote__container {
                left: calc(5% + 5rem);
                width: 100%;
                @include m(1085) {
                    left: 3rem;
                }
                @include m(826) {
                    left: 1.5rem;
                }
                @include m(768) {
                    top: auto;
                    bottom: -1rem;
                    left: 0;

                }
                @include m(480) {
                    bottom: -3rem;
                }
            }

        }
        &:first-child {
            .quote__container {
                left: calc(0% - 3rem);
                width: 100%;
                background-color: $blue--regular;
                @include m(826) {
                    left: -1.5rem;
                }
                @include m(768) {
                    top: auto;
                    bottom: -1rem;
                    left: 0;
                }
                @include m(480) {
                    bottom: -3rem;
                }
            }
        }
    }
}

.container--quotes {
    a:not(.button) {
        text-transform: uppercase;
    }
}


.wrapper--how-we-help .block:not(.padding--noBottom) {
    padding-bottom: 4rem!important;
}
.wrapper--about .block:not(#block--content__1):not(#block--content__2) {
    padding-bottom: 8rem!important;
    @include m(1024) {
        padding-bottom: 6rem!important;
    }
    @include m(915) {
        padding-bottom: 4rem!important;
    }
}